import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "chatContainer",
    "availabilityContainer",
    "input",
    "history",
    "availabilityHistory",
    "createServiceEventButton",
    "serviceProviderSelect",
    "bookableResourceSelect",
    "pickADate",
    "startTime",
    "successMessage",
  ];

  connect() {
    console.log("Student Services Chat controller connected");
    this.threadId = null;
    this.serviceDetails = null;
    this.pickADateTarget.addEventListener("change", this.chooseDate.bind(this));
  }

  setThreadId(id) {
    this.threadId = id; // Set thread ID after creation
  }

  setServiceDetails(details) {
    this.serviceDetails = details; // Store service details
  }

  toggleChat(event) {
    const serviceId = event.target.value;
    if (serviceId) {
      this.availabilityContainerTarget.classList.remove("hidden"); // Show
      this.chatContainerTarget.classList.remove("hidden"); // Show
      this.createThread(serviceId);
    } else {
      this.availabilityContainerTarget.classList.add("hidden"); // Hide
      this.chatContainerTarget.classList.add("hidden"); // Hide
    }
  }

  createThread(serviceId) {
    fetch("/student/services/create_thread", {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ service_id: serviceId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.thread_id) {
          console.log("Thread created with ID:", data.thread_id);
          this.setThreadId(data.thread_id);
          this.fetchServiceDetails(serviceId); // Fetch service details
        } else {
          console.error("Failed to create thread:", data.error);
        }
      })
      .catch((error) => console.error("Error creating thread:", error));
  }

  fetchServiceDetails(serviceId) {
    // Construct the URL dynamically based on the current location
    const url = `/student/service_details/${serviceId}`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          console.error("Error fetching service details:", data.error);
          return;
        }

        // Set service details for instructions
        this.setServiceDetails(data.message);

        // Populate the instructions textarea
        const instructionsTextarea = document.getElementById("instructionsTextarea");
        instructionsTextarea.value = this.prependCurrentDate(data.message);

        // Populate service provider dropdown
        const serviceProviderSelect = document.getElementById("service_provider_select");
        serviceProviderSelect.innerHTML = ""; // Clear existing options
        data.serviceProviders.forEach((provider) => {
          const option = document.createElement("option");
          option.value = provider.id;
          option.textContent = provider.name;
          serviceProviderSelect.appendChild(option);
        });

        // Populate bookable resource dropdown
        const bookableResourceSelect = document.getElementById("bookable_resource_select");
        bookableResourceSelect.innerHTML = ""; // Clear existing options
        data.bookableResources.forEach((resource) => {
          const option = document.createElement("option");
          option.value = resource.id;
          option.textContent = resource.name;
          bookableResourceSelect.appendChild(option);
        });

        // Update hidden fields for required service providers and resources
        const numServiceProvidersField = document.getElementById("num_service_providers_required");
        const numBookableResourcesField = document.getElementById("num_bookable_resources_required");
        if (numServiceProvidersField && numBookableResourcesField) {
          numServiceProvidersField.value = data.numServiceProvidersRequired || 0;
          numBookableResourcesField.value = data.numBookableResourcesRequired || 0;
          console.log("Hidden fields updated with service requirements:", {
            numServiceProvidersRequired: data.numServiceProvidersRequired,
            numBookableResourcesRequired: data.numBookableResourcesRequired,
          });
        } else {
          console.error("Hidden fields for service requirements not found.");
        }

        console.log("Dropdowns populated with service providers and bookable resources.");
      })
      .catch((error) => console.error("Error fetching service details:", error));
  }

  prependCurrentDate(message) {
    const now = new Date();
    const currentDate = now.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const currentTime = now.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return `**Today is ${currentDate} and the time is ${currentTime}.**\n\n${message}`;
  }

  send(event) {
    event.preventDefault(); // Prevent the form from submitting
    const message = this.inputTarget.value;
    this.inputTarget.value = ''; // Clear input after sending
    const userMessageElement = document.createElement('div');
    userMessageElement.classList.add('chatgpt-message', 'user');
    userMessageElement.textContent = `You: ${message}`;
    this.historyTarget.appendChild(userMessageElement);

    if (message.trim() !== '' && this.threadId) {
      this.scrollToBottom();

      // Show loading indicator
      this.showLoadingIndicator();
      const instructionsTextarea = document.getElementById(
        'instructionsTextarea'
      );
      const editedInstructions = this.prependCurrentDate(
        instructionsTextarea.value
      );

      if (['development', 'staging'].includes(process.env.RAILS_ENV)) {
        $('#editInstructionsModal').modal('show');

        document.getElementById('saveEditedInstructions').onclick = () => {
          this.createRunWithInstructions(message, editedInstructions);
          $('#editInstructionsModal').modal('hide'); // Close the modal after button click
        };
      } else {
        this.createRunWithInstructions(message, editedInstructions);
      }
    } else {
      console.error('Message is empty or thread ID is not set.');
      this.displayAssistantMessage(
        'Failed to send message. Thread ID is not set.'
      );
      this.addToAvailabilityHistory(
        'Failed to send message. Thread ID is not set.'
      );
    }
  }

  createRunWithInstructions(message, instructions = '') {
    const additionalMessages = [
      {
        role: 'user',
        content: instructions || this.serviceDetails,
      },
    ];

    fetch('/student/services/availability_chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
      },
      body: JSON.stringify({
        message: message,
        thread_id: this.threadId,
        additional_messages: additionalMessages,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.response) {
          // Now fetch the latest messages to update the chat box
          this.fetchThreadMessages();
        } else if (data.error) {
          console.error('Error from AI:', data.error);
          this.displayAssistantMessage(
            'Error processing your request. Please try again.'
          );
          this.addToAvailabilityHistory(
            'Error processing your request. Please try again.'
          );
        }
      })
      .catch(error => {
        console.error('Error:', error);
        this.displayAssistantMessage(
          'Failed to connect. Please check your connection and try again.'
        );
        this.addToAvailabilityHistory(
          'Failed to connect. Please check your connection and try again.'
        );
      });
  }

  displayAssistantMessage(text) {
    const uniqueId = `assistant-message-${Date.now()}`;
    const assistantMessageElement = document.createElement('div');
    assistantMessageElement.classList.add('chatgpt-message', 'assistant');
    assistantMessageElement.innerHTML = `Availability Assistant: <span id="${uniqueId}"></span>`;
    this.historyTarget.appendChild(assistantMessageElement);

    const assistantMessageSpan = document.getElementById(uniqueId);
    let i = 0;
    const speed = 5;
    const typeWriter = () => {
      if (i < text.length) {
        if (text.charAt(i) === '\n') {
          assistantMessageSpan.innerHTML += '<br>';
        } else {
          assistantMessageSpan.innerHTML += text.charAt(i);
        }
        i++;
        setTimeout(typeWriter, speed);
      } else {
        assistantMessageSpan.innerHTML = text.replace(/\n/g, '<br>');
        this.scrollToBottom();
      }
    };
    typeWriter();

    // No longer parsing `text` for date/time here.
    // Confirmation button logic is centralized elsewhere.
    console.log("Assistant message displayed:", text);
  }

  addToAvailabilityHistory(htmlText) {
    this.availabilityHistoryTarget.innerHTML = ''; // Clear existing content
    const availabilityMessageElement = document.createElement('div');
    availabilityMessageElement.innerHTML = htmlText;
    this.availabilityHistoryTarget.appendChild(availabilityMessageElement);
  }

  fetchThreadMessages() {
    fetch(`/student/services/fetch_thread_messages?thread_id=${this.threadId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.messages && data.messages.length > 0) {
          const lastMessage = data.messages[0];

          // Define regex to extract confirmation message within a <div>
          const confirmationRegex = /<div>([\s\S]*?)<\/div>/;
          const confirmationMatch = lastMessage.match(confirmationRegex);
          const confirmationMessage = confirmationMatch ? confirmationMatch[0].trim() : '';


          // Define regex to extract the HTML table snippet
          const htmlSnippetRegex = /<table[\s\S]*?<\/table>/; 
          const htmlMatch = lastMessage.match(htmlSnippetRegex);
          const htmlSnippet = htmlMatch ? htmlMatch[0].trim() : '';


          // Update the availability history section
          this.updateAvailabilityHistory(confirmationMessage, htmlSnippet);

          // Display the remaining message text in the chat history
          const cleanedMessage = lastMessage
            .replace(confirmationRegex, '')
            .replace(htmlSnippetRegex, '')
            .trim();
          if (cleanedMessage) {
            this.displayAssistantMessage(cleanedMessage);
          }
        } else {
          console.error('Failed to fetch messages:', data.error);
          this.displayAssistantMessage('Failed to retrieve conversation history.');
        }
        this.removeLoadingIndicator();
      })
      .catch((error) => {
        console.error('Error fetching messages:', error);
        this.displayAssistantMessage('Error retrieving messages.');
        this.removeLoadingIndicator();
      });
  }

  updateAvailabilityHistory(confirmationMessage, htmlSnippet) {
    console.log("updateAvailabilityHistory called.");
    console.log("Raw Confirmation Message:", confirmationMessage);
    console.log("Raw HTML Snippet:", htmlSnippet);

    // Clear existing content
    this.availabilityHistoryTarget.innerHTML = '';

    // Create a wrapper container for header and confirmation
    const wrapperContainer = document.createElement('div');
    wrapperContainer.className = 'availability-wrapper';

    // Create header for appointment request
    const headerElement = document.createElement('h4');
    headerElement.className = 'suggestion-header';
    headerElement.textContent = 'Appointment Request';

    // Create confirmation message container
    const confirmationContainer = document.createElement('div');
    confirmationContainer.className = 'confirmation-container';

    // Parse the confirmation message HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(confirmationMessage, 'text/html');

    // Traverse <p> elements, filter out "none", and append meaningful ones
    const paragraphs = doc.querySelectorAll('p');
    paragraphs.forEach((p) => {
      const strongText = p.querySelector('strong')?.textContent.trim();
      const content = p.textContent.replace(strongText, '').trim();

      if (content.toLowerCase() !== 'none') {
        confirmationContainer.appendChild(p.cloneNode(true));
      } else {
        console.log(`Skipping field with strongText '${strongText}' as it is 'none'.`);
      }
    });

    // Append confirmationContainer to the wrapper
    wrapperContainer.appendChild(headerElement);
    wrapperContainer.appendChild(confirmationContainer);

    // Create and append HTML snippet container
    const htmlContainer = document.createElement('div');
    htmlContainer.className = 'html-container';
    htmlContainer.innerHTML = htmlSnippet || '';
    this.availabilityHistoryTarget.appendChild(wrapperContainer);
    this.availabilityHistoryTarget.appendChild(htmlContainer);

    // Extract Date, Time, Service Providers, and Resources
    let dateText = null;
    let timeText = null;

    paragraphs.forEach((p) => {
      const strongText = p.querySelector('strong')?.textContent.trim();
      const content = p.textContent.replace(strongText, '').trim();

      if (content.toLowerCase() === 'none') {
        return; // Skip 'none' entries
      }

      switch (strongText) {
        case 'Date:':
          dateText = content;
          break;
        case 'Time:':
          timeText = content;
          break;
      }
    });

    console.log('Extracted Date:', dateText);
    console.log('Extracted Time:', timeText);

    if (dateText && timeText) {
      const [startTime, endTime] = timeText.split('to').map((time) => time.trim());

      console.log('Parsed Start Time:', startTime);
      console.log('Parsed End Time:', endTime);

      // Update the button
      this.updateConfirmationButton(dateText, startTime, endTime);
    } else {
      console.warn('Date or Time could not be extracted.');
      this.hideConfirmationButton();
    }
  }

  updateConfirmationButton(selectedDate, startTime, endTime) {
    if (this.createServiceEventButtonTarget) {
      this.createServiceEventButtonTarget.style.display = 'block';
      this.createServiceEventButtonTarget.textContent = `Confirm Booking for ${selectedDate} ${startTime} - ${endTime}`;
    } else {
      console.error('Create Service Event Button target is not found.');
    }
  }

  hideConfirmationButton() {
    if (this.createServiceEventButtonTarget) {
      this.createServiceEventButtonTarget.style.display = 'none';
    }
  }

  showLoadingIndicator() {
    if (!document.getElementById('message-text-loading')) {
      const loadingElement = document.createElement('div');
      loadingElement.classList.add('chatgpt-message', 'assistant');
      loadingElement.id = 'message-text-loading';
      loadingElement.innerHTML = `
        <div class="loading-placeholder" style="margin-bottom: 3px;"></div>
      `;
      this.historyTarget.appendChild(loadingElement);
      this.scrollToBottom();
    }
  }

  removeLoadingIndicator() {
    const loadingElement = document.getElementById('message-text-loading');
    if (loadingElement) {
      this.historyTarget.removeChild(loadingElement);
    }
  }

  scrollToBottom() {
    this.historyTarget.scrollTop = this.historyTarget.scrollHeight;
  }

  askNextClassAvailability() {
    this.inputTarget.value = 'What is the next available appointment time?';
    this.send(new Event('click'));
  }

  chooseDate(event) {
    const selectedDate = event.target.value; // This will be in the format 'YYYY-MM-DD'

    //We do the following to avoid having issues with timezones, to make sure the date that is picked by the user is the one we send on the string below
    const [year, month, day] = selectedDate.split('-').map(Number);
    const date = new Date(year, month - 1, day, 12, 0, 0);

    // Format the date to a more natural language format
    const formattedDate = date.toLocaleDateString('en-US', {
      weekday: 'long', // Full name of the day (e.g., 'Tuesday')
      month: 'short', // Short name of the month (e.g., 'Sep')
      day: '2-digit', // Two-digit day (e.g., '03')
      year: 'numeric', // Full numeric year (e.g., '2024')
    });

    this.inputTarget.value = `I would like to book on ${formattedDate}?`;
    this.send(new Event('click'));
  }

  createRequest() {
    console.log("createRequest called.");

    // Parse the confirmation message displayed on the page
    const confirmationContainer = document.querySelector('.confirmation-container');
    if (!confirmationContainer) {
      console.error("No confirmation container found.");
      alert("No confirmation message found.");
      return;
    }

    const paragraphs = confirmationContainer.querySelectorAll('p');
    if (paragraphs.length === 0) {
      console.error("No confirmation message found.");
      alert("No confirmation message found.");
      return;
    }

    let serviceProviderNames = [];
    let resourceNames = [];
    let dateText = null;
    let timeText = null;

    // Extract relevant data from the confirmation message
    paragraphs.forEach((p) => {
      const strongText = p.querySelector('strong')?.textContent.trim();
      const content = p.textContent.replace(strongText, '').trim();

      if (content.toLowerCase() === 'none') {
        return; // Skip 'none' entries
      }

      switch (strongText) {
        case 'Service Provider:':
          serviceProviderNames = content.split(',').map(name => name.trim());
          break;
        case 'Resource:':
          resourceNames = content.split(',').map(name => name.trim());
          break;
        case 'Date:':
          dateText = content;
          break;
        case 'Time:':
          timeText = content;
          break;
        default:
          console.warn(`Unexpected field: ${strongText}`);
      }
    });

    console.log('Extracted Service Providers:', serviceProviderNames);
    console.log('Extracted Resources:', resourceNames);
    console.log('Extracted Date:', dateText);
    console.log('Extracted Time:', timeText);

    if (!dateText || !timeText) {
      console.error("Date or Time could not be extracted.");
      alert("Invalid or missing date/time in the confirmation message.");
      return;
    }

    const [startTimeString, endTimeString] = timeText.split('to').map(time => time.trim());

    // Match with dropdown options
    const serviceProviderSelect = document.getElementById("service_provider_select");
    const bookableResourceSelect = document.getElementById("bookable_resource_select");

    const serviceProviderIds = Array.from(serviceProviderSelect.options)
      .filter(option =>
        serviceProviderNames.some(name => name.toLowerCase() === option.textContent.trim().toLowerCase())
      )
      .map(option => option.value);

    const resourceIds = Array.from(bookableResourceSelect.options)
      .filter(option =>
        resourceNames.some(name => name.toLowerCase() === option.textContent.trim().toLowerCase())
      )
      .map(option => option.value);

    console.log('Matched Service Provider IDs:', serviceProviderIds);
    console.log('Matched Resource IDs:', resourceIds);

    // Prepare and send payload
    const payload = {
      service_event_request: {
        user_id: document.getElementById("user_id").value,
        subdomain_id: document.getElementById("subdomain_id").value,
        service_id: document.querySelector("[name=service_id]").value,
        start_time: `${dateText} ${startTimeString}`,
        end_time: `${dateText} ${endTimeString}`,
        status: "requested",
      },
      resource_service_event_requests: [
        ...serviceProviderIds.map(id => ({
          bookable_resource_id: id,
          start_time: `${dateText} ${startTimeString}`,
          end_time: `${dateText} ${endTimeString}`,
        })),
        ...resourceIds.map(id => ({
          bookable_resource_id: id,
          start_time: `${dateText} ${startTimeString}`,
          end_time: `${dateText} ${endTimeString}`,
        })),
      ],
    };

    console.log('Payload:', JSON.stringify(payload, null, 2));

    fetch("/student/services/confirm_time", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content,
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.service_event_request_id) {
          console.log("Service Event Request created successfully:", data);
          this.showSuccessMessage(); // Show success message
        } else {
          console.error("Service event request creation failed:", data.errors);
        }
      })
      .catch(error => console.error("Error creating service event request:", error));
  }

  // Helper function to convert 12-hour to 24-hour format
  convertTo24Hour(hour, isPM) {
    if (isPM && hour !== "12") {
      return parseInt(hour, 10) + 12;
    } else if (!isPM && hour === "12") {
      return 0;
    }
    return parseInt(hour, 10);
  }

  // Helper function to match IDs based on text content of options
  getMatchingIds(selectId, names) {
    const selectElement = document.getElementById(selectId);
    if (!selectElement) {
      console.error(`Element with ID '${selectId}' not found.`);
      return [];
    }

    const selectedIds = [];
    Array.from(selectElement.options).forEach((option) => {
      if (names.some(name => name.toLowerCase().trim() === option.textContent.toLowerCase().trim())) {
        selectedIds.push(option.value);
      }
    });

    return selectedIds;
  }

  showSuccessMessage() {
  // Fetch the success message partial
  fetch("/student/services/success_message")
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to load success message partial.");
      }
      return response.text();
    })
    .then((html) => {
      // Insert the success message HTML into the body
      const overlay = document.createElement("div");
      overlay.classList.add("success-overlay");
      overlay.innerHTML = html;
      document.body.appendChild(overlay);

      // Hide the form and other content
      const studentServicesWidget = document.querySelector(".student-services-widget");
      if (studentServicesWidget) {
        studentServicesWidget.style.display = "none";
      }

      console.log("Success message overlay displayed.");
    })
    .catch((error) => console.error("Error fetching success message:", error));
  }
}
